import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';
import { SubscriptionPlan } from 'src/app/ui-main/types/subscription-plan';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss']
})
export class SubscriptionCardComponent implements OnInit {

  @Input() subscriptionType: string = '';
  @Input() activated: boolean = false;
  @Input() possible: boolean = false;
  @Input() status: string = '';
  @Input() oneActive: boolean = false;
  @Input() raised: boolean = false;
  @Input() subscriptionDesc: SubscriptionPlan;
  @Input() updateTrigger = '';
  
  EN = {
    year : 'year',
    demand : 'price on demand',
    extra : 'Extra Services',
    choose : 'Choose Plan',
    contact : 'Contact Us',
    selected : 'Selected',
    hoverMessage : 'Payment and Activation is done in Settings',
    subscribe: 'Subscribe',
    upgrade: 'Upgrade',
    currentPlan: 'Current Plan',
  }
  @Output() activate = new EventEmitter<any>();

  ES = {
    year : 'año',
    demand : 'precio a demanda',
    extra : 'Servicios Extra',
    choose : 'Elegir Plan',
    contact : 'Contáctanos',
    selected : 'Seleccionado',
    hoverMessage : 'El pago y la activación se realizan en Configuración',
    subscribe: 'Suscribirse',
    upgrade: 'Actualizar',
    currentPlan: 'Plan Actual',
  }

  DE = {
    year : 'jahr',
    demand : 'Preis auf Anfrage',
    extra : 'Zusätzliche Dienstleistungen',
    choose : 'Plan auswählen',
    contact : 'Kontaktiere uns',
    selected : 'Ausgewählt',
    hoverMessage : 'Zahlung und Aktivierung erfolgen in den Einstellungen',
    subscribe: 'Abonnieren',
    upgrade: 'Aktualisieren',
    currentPlan: 'Aktueller Plan',
  }

  constructor(private messageService: MessageService,
              public textFragments : TextFragmentService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  subscribe(id: string) {
    const msg = {
      name: 'subscribeSubscriptionPlan',
      args: [id, false, 0, "new"] // 0 credit, workflow: new
    };
    this.messageService.sendMsg(msg, 'main');
    this.activate.emit();
  }
  upgrade(id: string) {
    const msg = {
      name: 'upgradeSubscriptionPlan',
      args: [id]
    };
    this.messageService.sendMsg(msg, 'main');
    this.activate.emit();
  }

}
