import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeleteProductDialogComponent } from '../delete-product-dialog/delete-product-dialog.component';

import { QRCodeElementType } from 'angularx-qrcode'; // https://www.npmjs.com/package/angularx-qrcode
import { SafeUrl } from '@angular/platform-browser';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { CloneProductDialogComponent } from '../clone-product-dialog/clone-product-dialog.component';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

  public qrCodeDownloadLink: SafeUrl = "";
  public url: string = "";
  public elementType: QRCodeElementType = "canvas";
  // create a product interface !!!!
  @Input() product: any;
  @Input() status: string;

  @Output() previewAction = new EventEmitter();
  @Output() deleteProductAction = new EventEmitter();
  @Output() cloneProductAction = new EventEmitter();

  public deleteConfirmation: boolean = false;
  public cloneConfirmation: boolean = false;

  EN = {
    brand : "Brand",
    product : "Product",
    batches : "Number of Batches",
  }

  ES = {
    brand : "Marca",
    product : "Producto",
    batches : "Número de Lotes",
  }

  DE = {
    brand : "Marke",
    product : "Produkt",
    batches : "Anzahl der Chargen",
  }

  constructor(private router: Router, 
              public dialog: MatDialog, 
              public textFragments: TextFragmentService) {
    
  }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    
    const absUrl = window.location.origin;
    console.log("absUrl: ", `${absUrl}/app?id=${this.product.id}`);
    this.qrCodeDownloadLink = `${absUrl}/app?id=${this.product.id}`
  }

  previewClicked() {
    this.previewAction.emit({id: this.product.id})
  }

  toProductInfo() {
    this.router.navigateByUrl(`/main/product-info-view/${this.product.id}`)  // /${this.product.batch_number}
  }

  onCodeChange(url: any) {
    this.url = url;
  }

  saveAsImage(parent: any) {
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.")
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "Vistrace-QR-Code"
      link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }

  public copyProduct() {
    const dialogRef = this.dialog.open(CloneProductDialogComponent, {
      data: { productName: this.product.name },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog Closed with result: ', result);
      this.cloneConfirmation = result;
      if (this.cloneConfirmation) {
        console.log("Cloning product with id: ", this.product.id);
        this.cloneProductAction.emit(this.product.id);
      }
    });
  }

  deleteProduct(): void {
    const dialogRef = this.dialog.open(DeleteProductDialogComponent, {
      data: { productName: this.product.name },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog Closed with result: ', result);
      if (result.delete) {
        this.deleteProductAction.emit(this.product.id);
      }
    });
  }
  

}
