import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe: Stripe | null = null;
  private publishableKey: string = 'pk_test_51J3k8vKdBghYZ0A8VVCBErEapgorIecJwW99dYTZ8xC7ZdkHEENEeRb4kQsv0uutdoy0z9Y6vh1Mzw6ptfw2Oryz00XVCB9mXm';
  private subscriptions: Array<Subscription>=[];
  client_secret = "";
  payment_data: any;
  constructor(
    private messageService: MessageService,
    public dialog: MatDialog,
    public spinnerService: NgxSpinnerService
    ) {
    this.initializeStripe();
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === "confirmPaymentProcess"))
        .subscribe( msg => {
          // show up a confirmation box
          // console.log("Show confirmation box", msg);
          //this.spinnerService.hide();
          this.payment_data = {
            purchase_intent_id: msg.args.purchase_intent_id
          }
          // this.confirmPayment(msg); // skipped for now
          this.client_secret = msg.args.client_secret;
          this.handlePayment();        // takes care for 3d etc.
        }
    ));
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === "confirmPaymentMethod"))
        .subscribe( msg => {
          this.client_secret = msg.args.client_secret;
          this.confirmSetupIntent();
        }
    ));
  }

  /*
  confirmPayment(msg: any) {
    
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: this.payment_data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog Closed with result: ', result);
      if (result.approved) {
        this.handlePayment();  // finally handle the payment
      }
    });
  }
  */

  async initializeStripe() {
    console.log("Initializing Stripe...");
    try {
      this.stripe = await loadStripe(this.publishableKey);
      console.log("Stripe initialized successfully", this.stripe);
    } catch (error) {
      console.error("Error initializing Stripe:", error);
    }
  }

  async handlePayment() {

    const result = await this.stripe.confirmCardPayment(this.client_secret);
    this.client_secret = "";   // reset client secret after ok
    console.log(result);
    this.spinnerService.hide();
    if (result.error) {
      // Show error to your customer
      console.error(result.error.message);
      const msg = {
        name: 'paymentError',
        args: [result.paymentIntent, this.payment_data]
      };
      this.messageService.sendMsg(msg);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded!');
        const msg = {
          name: 'paymentSuccess',
          args: [result.paymentIntent, this.payment_data]
        };
        this.messageService.sendMsg(msg);
      }
    }
  }

  async confirmSetupIntent() {
    try {
      const result = await this.stripe.confirmCardSetup(this.client_secret);
      this.client_secret = ''; // reset the secret 
      if (result.error) {
        const msg = {
          name: 'paymentMethodInvalidate',
          args: []
        };
        this.messageService.sendMsg(msg);
      } else {
        // SetupIntent confirmed successfully
        const msg = {
          name: 'paymentMethodValid',
          args: []
        };
        this.messageService.sendMsg(msg);
      }
    } catch (error) {
      const msg = {
        name: 'paymentMethodInvalidate',
        args: []
      };
      this.messageService.sendMsg(msg);
    }
  }

  getStripe(): Stripe | null {
    return this.stripe;
  }

  async createPaymentMethod(cardElement: StripeCardElement, cardholderName: string) {
    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardholderName,
      },
    });
    if (error) {
      this.handlePaymentMethodErrors(error);
    } else {
      this.handlePaymentMethod(paymentMethod);
    }
  }

  handlePaymentMethod(paymentMethod: any) {
    const callbackId = "finishHandlePayment"
    const msg = {
      name: 'handlePaymentMethod',
      args: [paymentMethod, callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  handlePaymentMethodErrors(paymentMethod: any) {
    const msg = {
      name: 'errorPaymentMethod',
      args: [paymentMethod]
    };
    this.messageService.sendMsg(msg, 'main');
  }


}
