import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit, OnDestroy {

  private subscriptions = new Array<Subscription>();

  public products: any[] = [];
  public mobile_url: SafeResourceUrl;
  public selectedProductId = "";
  public leftover_product_number = 0;

  constructor(
    private sanitizer: DomSanitizer, 
    private messageService: MessageService, 
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private uiInfoService: UiInfoService) { }

  ngOnInit(): void {
    const callbackGetProductId = "getProductIds"
    const callbackDeleteProductId = "deleteProductIds"
    
    this.subscriptions.push(
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === "bookProduct"))
        .subscribe((msg) => {
          this.spinnerService.hide();
          this.router.navigateByUrl(msg.args.endpoint);
        }));

    this.subscriptions.push(
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackGetProductId))
        .subscribe((msg) => {
          this.products = msg.args.product_list;
          this.leftover_product_number = msg.args.leftover_product_number;
        }));
    
    this.subscriptions.push(
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackDeleteProductId))
        .subscribe((msg) => {
            this.uiInfoService.openSnackbar("Product deleted", 4000)
        }));
    
    const callbackCopyProduct = "copyProduct"
    this.subscriptions.push(
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackCopyProduct))
        .subscribe((msg) => {
          this.uiInfoService.openSnackbar("Product Copied", 4000)
        }));
        
    const getProductIdMessage = {
          name: 'getProductIds',
          args: [callbackGetProductId]
    };
    this.messageService.sendMsg(getProductIdMessage, 'main');

    
  }

  launchPreview(data: any) {
    console.log("LAUNCH", data);
    const user = this.messageService.user;
    console.log("USER", user);
    //let url = "https://data.vistrace.quantecdc.es/?id=" + id;
    let url = "/app?id=" + data.id+"&batch="+data.batch_number+"&user="+user+"&forward=1";
    this.urlSanitizer(url);
    this.selectedProductId = data.id;
    console.log("selected", this.selectedProductId);
  }

  bookProduct(event: any) {
    const bookProductMessage = {
      name: 'bookProduct',
      args: []
    };
    this.messageService.sendMsg(bookProductMessage, 'main');
    this.spinnerService.show()

    
  }

  deleteProduct(productId: any) {
    const callbackDeleteProductId = "deleteProductIds"
    console.log("Delete action for product id: " + productId)
    const deleteProductMessage = {
      name: 'deleteProductById',
      args: [productId, callbackDeleteProductId]
    };
    this.messageService.sendMsg(deleteProductMessage, 'main');
  }

  copyProduct(productId: any) {
    const callbackCopyProduct = "copyProduct"

    console.log("Clone action for product id: " + productId)
    const cloneProductMessage = {
      name: 'copyProduct',
      args: [productId, callbackCopyProduct]
    };
    this.messageService.sendMsg(cloneProductMessage, 'main');
  }

  urlSanitizer(url: string) {
    this.mobile_url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
