
  <div class="flex flex-1 overflow-hidden lg:px-[8%] h-full">
    <!-- SideMenu -->
    <aside class="w-64 h-full flex-shrink-0">
      <app-side-menu></app-side-menu>
    </aside>
    <!-- Dashboard -->
    <main class="flex-1 pb-6 h-full overflow-y-auto overflow-x-hidden">
      <section class="w-full 2k:mb-20 mb-12 relative">
        <img class="w-full hero-image" id="image1" src="assets/images/home_1.png">
        <img class="w-full hero-image" id="image2" src="assets/images/home_2.png">
      </section>
      <section *ngIf="activeSubscription">
        <ul class="w-full flex flex-row justify-around mt-[520px]">
          <!--app-vistrace-card title="Subscription status" img_src="assets/plusIcon.svg" text_description="Expiring date:" text_content="16/09/2024"></app-vistrace-card-->
          <app-vistrace-card
            cardType="suscriptionStatus"
            [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subscriptionStatus"
            [text_description]="toUpperCaseString(serviceName)"
            [text_content]="expiringDate"
            img_src="assets/suscriptionPurchased.svg">
          </app-vistrace-card>

          <app-vistrace-card
            *ngIf="this.products.length!=0"
            cardType='productAdded'
            [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.recentlyAdded" 
            img_src="assets/addedProduct.svg" 
            [text_description]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productName" 
            [text_content]='lastProduct'>
          </app-vistrace-card>
          <div *ngIf="this.products.length==0" (click)="openCanvasVideo()" class="w-[280px] h-[280px] flex flex-column p-5 rounded-lg shadow-lg overflow-hidden border-vistrace-card items-center justify-center text-lg font-semibold text-gray-800 hover:cursor-pointer">
            <p class="text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.needHelp}}</p>
            <img class="hover:cursor-pointer" src="assets/tutorialVisTrace.png">
            <p class="text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.getToKnow}}</p>
          </div>

          <app-vistrace-card [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.usage" 
            cardType="serviceUsage"
            img_src="assets/serviceUsage.svg" 
            [text_content]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.registered">
          </app-vistrace-card>
        </ul>
      </section>
      <section *ngIf="!activeSubscription" class="w-full flex justify-center align-middle mt-[520px]">
        <button (click)="toSubscriptions()" class="w-2/3 h-12 mt-10 border-no-sub font-bold text-2xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.choose}}</button>
      </section>
      <section *ngIf="!activeSubscription" class="mt-[-100px]">
        <div class="flex flex-column p-5 rounded-lg overflow-hidden items-center justify-center text-lg font-semibold text-gray-800 ">
          <p class="text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.new}}</p>
          <img (click)="openCanvasVideo()" class="hover:cursor-pointer" src="assets/tutorialVisTrace.png">
          <p class="text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.tutorial}}</p>
        </div>
      </section>
      <section class="mt-5 mx-10 bg-vistrace-selected" >
        <p class="pt-2 pb-4 text-center font-bold text-2xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.faq}}</p>
        <div *ngFor="let item of data;let i = index;">
          <button class="accordion font-medium text-lg text-left" (click)="toggleAccordian($event, i)"> {{item.parentName}} </button>
          <div class="panel" hide="!item.isActive">
            <p *ngFor="let child of item.childProperties" class="py-2"> {{child.propertyName}} </p>
          </div>
        </div>
      </section>
    </main>
    <!-- StatisticCard -->
    <div class="h-full flex flex-col justify-between w-64 bg-gray-100 p-4">
      <!--app-voice-input></app-voice-input-->
      <!-- <app-statistics></app-statistics> -->
      <!-- <header class="flex flex-row-reverse">
        <img
          src="assets/plusIcon.svg"
          class="m-4 cursor-pointer"
          alt="add product"
        />
        <img
          src="assets/plusIcon.svg"
          class="m-4 cursor-pointer"
          alt="add product"
        />
      </header>
      <section class="mt-48">
        <img class="w-full h-full" src="assets/analisys-placeholder.png" alt="add product" />
      </section>
      <section class="h-full flex flex-col-reverse justify-start items-end">
        
        
        
      </section> -->
      <app-right-column-card class="h-[500px]"></app-right-column-card>
      <div class="z-50">
        <app-chat-bot *ngIf="openChatWindow"></app-chat-bot>
      </div>
      <div class="w-full flex flex-col-reverse items-end ">
        <button
          class="inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16 bg-black hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900"
          type="button" aria-haspopup="dialog" aria-expanded="false" data-state="closed" (click)="openChatBot()">
          <svg xmlns=" http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="text-white block border-gray-200 align-middle">
            <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" class="border-gray-200">
            </path>
          </svg>
        </button>
      </div>

    </div>
  </div>

  


