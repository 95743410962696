import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DialogLoginService } from 'src/app/shared-generic/services/dialog-login.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { UpdateService } from 'src/app/shared-generic/services/update.service';
import { TextFragmentService } from '../../services/text-fragment.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  EN = {
    title: 'Login',
    email: 'Email',
    password: 'Password',
    forgotPasswordText: 'Forgot your password?',
    loginButtonText: 'Login',
    registerText: 'Register for free',
    privacyText: 'Privacy',
  };

  ES = {
    title: 'Iniciar sesión',
    email: 'Correo electrónico',
    password: 'Contraseña',
    forgotPasswordText: '¿Olvidaste tu contraseña?',
    loginButtonText: 'Iniciar sesión',
    registerText: 'Regístrate gratis',
    privacyText: 'Privacidad',
  };

  DE = {
    title: 'Anmeldung',
    email: 'E-Mail',
    password: 'Passwort',
    forgotPasswordText: 'Haben Sie Ihr Passwort vergessen?',
    loginButtonText: 'Anmeldung',
    registerText: 'Kostenlos registrieren',
    privacyText: 'Datenschutz',
  }


  email: string = '';
  password: string = '';
  mode = "login";
  autologin = false;
  public subs: Subscription[] = [];
  constructor(
    public msgService: MessageService,
    public router : Router,
    public messageService: MessageService,
    public loginService: DialogLoginService,
    public _Activatedroute: ActivatedRoute,
    public updateService: UpdateService,
    private spinnerService: NgxSpinnerService,
    private dialogLoginService: DialogLoginService,
    private uiInfoService : UiInfoService,
    public textFragments : TextFragmentService,
    //public router: Router
    ) { }

  onSubmit(): void {
    // // NOTE only necessary call to login is:
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailPattern.test(this.email)) {
    //   this.uiInfoService.openSnackbar("You must enter a valid email", 3000);
    // } else if (this.password.length < 8) {
    //   this.uiInfoService.openSnackbar("Password must be at least 8 characters long", 3000);
    // } else {
      this.dialogLoginService.login(this.email, this.password);
    // }
  }


  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.subs.push(this._Activatedroute.paramMap.subscribe(params => { 
      this.mode = params.get('id'); 
      this.loginService.shouldRun.next(false);
      if (this.mode == "login") {
        this.startNewLogin(); 
      }  else if (this.mode == "autologin") {
        this.autologin = true;
        const prom = this.spinnerService.show();
        prom.then(value => {
          console.log('resolved', value);
        });
        console.log("in login view with autologin");
      } else if (this.mode == "wait") {
        const prom = this.spinnerService.show();
        prom.then(value => {
          console.log('resolved', value);
        });
      } else {
        this.startNewLogin(); 
      }
    }));

    this.subs.push(this.updateService.subscribe((upd: string) => {
      if (upd.split(" ")[1]===this.messageService.final_auth_backend) {
        if (upd.startsWith('failedAuth')) {
          // get some failed auth information before....
          this.startNewLogin();
        } 
      }
    }));

    
  }


  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  public startNewLogin():void {
  }

  toRegister() {
    this.router.navigateByUrl("/register")
  }

  toPasswordRecover() {
    this.router.navigateByUrl("/password-recover")
  }

  openAndDownload(event: MouseEvent, url: string): void {
    event.preventDefault(); // Prevent the default anchor tag behavior

    // Open the PDF in a new tab
    window.open(url, '_blank');

    // Create an element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'download.pdf'; // Dynamically getting the file name from URL
    document.body.appendChild(link);
    link.click(); // Programmatically click the link to start the download
    document.body.removeChild(link); // Remove the element after download
  }
}
