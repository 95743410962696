<div class="flex lg:px-[8%] h-full justify-center pt-16 mb-5 overflow-auto">
    <div class="rounded w-96">
        <div class="flex justify-center mb-4">
            <h2 class="text-2xl font-semibold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.title}}</h2>
        </div>
        <form (ngSubmit)="onSubmit()">
            <div class="mb-4">
                <label for="username" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.email}}</label>
                <input type="text" name="username" [(ngModel)]="email" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
            </div>
            <div class="mb-4">
                <label for="password" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.password}}</label>
                <input type="password" [(ngModel)]="password" name="password" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
            </div>
            <div class="flex flex-col items-center justify-between mb-4">
                <!--div class="flex items-center">
                    <input type="checkbox" name="rememberMe" class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded">
                    <label for="rememberMe" class="ml-2 block text-sm text-gray-900">Remember me</label>
                </div-->
                <a (click)="toPasswordRecover()" class="text-sm text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.forgotPasswordText}}</a>
            </div>
            <button (click)="onSubmit()" type="submit" class="w-full bg-vistrace-primary text-white py-2 px-4 rounded-md hover:bg-vistrace-quinary focus:outline-none focus:bg-vistrace-quinary">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.loginButtonText}}</button>
            <div class="mt-4 mb-8 pb-4 text-center">
                <a (click)="toRegister()" class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.registerText}}</a><br>
                <a href="/static/legals/vistrace-privacy-policy.pdf" download="vistrace-privacy-policy.pdf" (click)="openAndDownload($event, '/static/legals/vistrace-terms-of-service.pdf')" class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.privacyText}}</a>
            </div>
        </form>
    </div>
</div>