import { Injectable, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { UpdateService } from './update.service';
import { DialogLoginService } from './dialog-login.service';
import { Message } from '../types/message';
import { Dictionary } from '../types/dictionary';
import { share, map, filter } from 'rxjs/operators';
import { sha256, sha224 } from 'js-sha256';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
import { KeycloakService } from 'keycloak-angular';

// we use absUrl instead:
// const URL = 'ws://127.0.0.1:9000/ws';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public messageDict = new Dictionary<Subject<Message>>();
  public log = '';
  public user = '';
  public dynamic_port = '8100';
  public anonymous_id = '';
  public login = false;
  public password = '';
  public isAuthenticated = false;
  public dataStream = new Dictionary<Subject<Message>>();
  public login_error = '';
  public absUrl = '';
  public protocol = 'http:';
  public salt = "";
  public token = "";
  public hostname = "";
  public redirect_token = "";
  public jwt_token = "";
  public redirect_url = "";
  public secret_number = "";
  public email = "";
  public confirmation_number = "";
  public news_key = "";
  public product_key = "";
  public bulletin_key = "";
  public blog_key = "";
  public app_name = "";
  public app_autologin = true;
  // these backends are set by the SALT call
  private backends: any;
  public final_urls: any;
  private header: any;
  public version: string;
  public do2fa = false;
  public final_auth_backend = "";
  public default_backend = "main";
  public startApp = "";
  public rootUrl = '';
  public mount_success = "";
  public client_name = "";
  public show_quality_management = true;

  constructor(private wsService: WebsocketService,
    private updateService: UpdateService,
    private dialogLoginService: DialogLoginService,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private projectService: ProjectSetupService,
    public keycloak: KeycloakService) {
    // this.getSalt();
    this.header = new Headers({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    this.dialogLoginService.connectSubject.subscribe(data => {
      //this.redirect_token = data.token;
      console.log("connect with", data);
      this.connect(data.username, data.password);
      // this.restartApps(); MARKER#1
    });
    this.updateService.update.pipe(filter(msg => msg === "logout")).subscribe(()=> {
      // console.log("forced login....");
      this.logout();
    });
    this.updateService.update.pipe(filter(msg => msg.startsWith("reconnect"))).subscribe(msg=> {
      
      const key = msg.split(" ")[1];  // this is the backend
      console.log("reconnect ....", key);
      this.connectBack(this.user, this.password, key);
    });
    this.absUrl = window.location.href;
    this.protocol = window.location.protocol;
    console.log("MESSAGE SerVICE CONSTRUCTOR");
  }

  // NOTE: this is never called
  public restartApps() {
    for (const key in this.backends) {
      if (this.dialogLoginService.apps.indexOf(key) !== -1) {
        const msg = {
          name: 'restartApp',
          args: [],
          action: 'default'
        }
        this.sendMsg(msg, key);
      }
    }
  }

  public init() {
    console.log("INIT MESSAGE");
  }

  public getSalt() {
    console.log("get salt...");
    this.httpClient.get(`/api-main/get_salt?cache=${this.getRandomInt(10000)}`, {headers: this.header}).subscribe(
      resp => {
        this.salt = resp["salt"];
        // console.log("SALT", this.salt);
        this.dialogLoginService.loginMode = resp["loginMode"];
        console.log("loginMode", resp["loginMode"]);
        this.dialogLoginService.login_mode_available(true);
        this.dialogLoginService.apps = resp["apps"];
        this.startApp = resp["startApp"];
        this.backends = resp["backends"];
        const backs = Object.keys(this.backends);
        this.final_auth_backend = backs[backs.length-1];
        console.log("BACKENDS", this.backends, this.final_auth_backend)
        this.final_urls = resp["final_urls"];
        this.user = resp["session_username"];
        this.hostname = resp["hostname"];
        this.redirect_token = resp["redirect_token"];
        this.redirect_url = resp["redirect_url"];
        this.secret_number = resp["secret_number"];
        this.email = resp["email"];
        this.confirmation_number = resp["confirmation_number"];
        this.news_key = resp["news_key"];
        this.product_key = resp["product_key"];
        this.bulletin_key = resp["bulletin_key"];
        this.blog_key = resp["blog_key"];
        this.default_backend = resp["default_backend"];
        this.version = resp["version"];
        this.app_name = resp["app_name"];
        if (resp["dynamic_port"]!==undefined) {
          this.dynamic_port = resp["dynamic_port"];
        }
        if (resp["app_autologin"]!==undefined) {
          this.app_autologin = resp["app_autologin"]
        }
        this.mount_success = resp["mount_success"];
        this.client_name = resp["client_name"];
        if (resp["app_autologin"]!==null) {
          this.show_quality_management = resp["show_quality_management"];
        }
        console.log("VERSION", this.version);
        // user comes in if a session exists and username is not anonymous ...
        if (this.confirmation_number && this.confirmation_number?.length>0) {
          // console.log("CONFIRMATION number", this.confirmation_number);
          // for addimap: use another user information channel
          // should open new login here with new e-mail inserted
          let date: Date = new Date("2024-11-11");
          this.user = this.confirmation_number.split("-")[1]; 
          this.cookieService.put("addimap_user", this.user, {expires: date})
          // this.dialogLoginService.loginSubject.next("start-confirmation");
          this.connect(this.user, "");
          return;
        }
        this.updateService.push("version-check");
        if ((this.user?.length > 0) && (this.secret_number?.length===0)) {
          console.log("salt user successfull", this.user, this.app_autologin)
          if (this.app_autologin) {
            this.connect(this.user, "");
          }
        } else {
          console.log("salt autologin ....number... ", this.secret_number)
          this.dialogLoginService.secret_number = this.secret_number;
          setTimeout(()=>this.updateService.push("autologin"), 500);
        }
      });
  }

  public logout() {
    console.log("LOGOUT now...");
    // QUES: do we need keycloak here?
    this.login = false;
    this.httpClient.get('/logout?user='+this.user).subscribe(
      resp => {
        console.log(resp);
        this.close_all(this.user);
        if (this.app_name == "addiplan") {
          if (this.dialogLoginService.loginMode === "keycloak") {
            this.keycloak.logout(this.rootUrl).then(()=>
              this.router.navigateByUrl("/login-view/wait")
            )
          } else {
            this.router.navigateByUrl("/login-view/login")
          }
        }
      });
  }

  public disconnect() {
    console.log("DISCONNECT now.....");
    this.login = false;
    this.close_all(this.user);
    if (this.app_name=="addimap") {
      const rand = this.getRandomInt(10000000).toString();
      this.user = "anonymous/"+rand;
      this.redirect_token = "";
      this.dialogLoginService.login(this.user, "");
    } else {  // e.g. addiplan
      if (this.dialogLoginService.loginMode === "keycloak") {
        this.keycloak.logout(this.rootUrl).then(()=>
          this.router.navigateByUrl("/login-view/wait")
        )
      } else {
        this.router.navigateByUrl("/login-view/login")
      }
    }
  }

  public connect(user: string, password: string) {
    if (this.login) {
      this.httpClient.get('/logout?user='+this.user).subscribe(
        // QUES: do we need keycloak here?
        resp => {
          console.log(resp);
          this.close_all(this.user);
          // this.router.navigateByUrl("/login-view/"+mode);
        
          console.log('try to connect main with previous logout ', user);
          Object.keys(this.backends).forEach( key => {
          // console.log(key);
          this.connectBack(user, password, key);
        });
      });
    } else {
      console.log('try to connect main normally ', user);
      Object.keys(this.backends).forEach( key => {
        // console.log(key);
        this.connectBack(user, password, key);
      });
    }
  }

  public close_all(user: string) {
    this.login = false;
    // console.log('try to close ws-connection for', user);
    Object.keys(this.backends).forEach( key => {
      // console.log('backend:', key);
      this.closeBack(user, key);
    });
  }

  private connectBack(user: string, password: string, backend: string): void {
    // has to be called for each backend individually
    // console.log('try to connect -- ', user, password);
    const url = this.absUrl.split('/');
    if (this.protocol === 'https:') {
        url[0] = 'wss:';
    } else {
        url[0] = 'ws:';
    }
    url[3] = this.backends[backend];
    this.absUrl = url.join('/');
    this.messageDict.add(backend, <Subject<Message>>this.wsService
      .connect(this.absUrl, backend, user).pipe(
        map((response: MessageEvent): Message => {
          const data = JSON.parse(response.data);
          return {
            name: data.name,
            args: data.args,
            action: data.action,
            backend: data.backend
          };
        }), share()));
    const message = this.messageDict.item(backend);
    message.subscribe(msg => {
      //console.log(".....", msg);
      this.manageMsg(msg);
    });
    this.user = user;
    // this.login = true;
    this.password = password;
  }

  private closeBack(user: string, backend=this.default_backend): void {
    // disconnect backends
    this.wsService.close(backend);
    
    // console.log('disconnected', user, 'from', backend);
  }

  // separate between different backends here
  public awaitMessage(backend=this.default_backend) {
    if (!this.dataStream.item(backend)) {
        this.dataStream.add(backend, new Subject<Message>());
      }
    
    return this.dataStream.item(backend);
  }

  public getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  public getLog(): string {
    return this.log;
  }

  public close() {
    Object.keys(this.backends).forEach( key => {
        this.wsService.close(key) });
    this.login = false;
  }

  public sendMsg(msg: Message, backend=this.default_backend, wait=true) {
    // send a message via websocket connection to python backend (here)
    msg.user = this.user;
    msg.token = this.token;
    if (msg.name === '') {
      return;
    } else {
        const con = this.messageDict.item(backend);
        // console.log("send ", backend, msg, this.login)
        if ((this.login)||(!wait)) 
        {
          con.next(msg); 
        } else {
          setTimeout(()=> this.sendMsg(msg, backend), 500);
        }
    }
  }

  public hexdigest_n(input: string, n: number) {
    let i = 0;
    let pre_digest = sha256(input);
    while (i < n - 1) {
      i += 1;
      pre_digest = sha256(pre_digest);
    }
    return pre_digest;
  }

  private manageMsg(msg: Message): void {
    if (msg.name === 'Log') {
      if (this.log.length > 1500) { this.log = this.log.slice(msg.args.length); }
      this.log += msg.args;
    } else if (msg.name === 'doAuth') {
      // console.log(".....doAuth", msg);
      if (msg.action === 'authenticate') {
        const id = msg.args['id'];
        const nonce = msg.args['nonce'];
        //console.log("nonce", nonce)
        const pre_digest = this.hexdigest_n(this.password + this.salt, 100);
        //console.log("pre digest (secret)", pre_digest);
        var challenge;
        //if (this.token_challenge.length > 0) {
        //  challenge = this.token_challenge;
        //} else {
        challenge = this.hexdigest_n(pre_digest + nonce, 100);
        //}
        const authMsg = {
          name: 'doAuth',
          args: { 'user': this.user, 'challenge': challenge, 
                  'id': id, 'redirect_token': this.redirect_token,
                  'jwt_token': this.jwt_token
                },
          action: 'default'
        };
        this.sendMsg(authMsg, msg.backend, false);
      } else {
        this.isAuthenticated = msg.args['authenticated'];
        if (msg.args['token']) {
          this.token = msg.args['token'];
        }
        this.do2fa = msg.args['do2fa'];
        // NOTE: set user as the identifier stored in the db (case sensitive), all following backend requests will state this identifier
        // console.log("***** case_sensitive_user: ", msg.args['case_sensitive_user'])
        if (msg.args['case_sensitive_user']) {
          this.user = msg.args['case_sensitive_user'];
        }
        console.log("TOKEN", this.token, this.do2fa, this.user);
        if (!msg.args['authenticated']) {
          // console.log('auth failed');
          this.login_error = msg.args.error;
          if (msg.backend == this.final_auth_backend) {
            console.log("show error", this.login_error)
            this.dialogLoginService.loginError(this.login_error);
          }
          this.dialogLoginService.shouldRun.next(false);
          this.updateService.push("failedAuth " + msg.backend);
          console.log("FAILED AUTH...");
          this.wsService.close(msg.backend);
        } else {
          // console.log('auth success', msg.backend);
          // next is important in app component
          this.updateService.push("doneAuth " + msg.backend);
          // this.dialogLoginService.close_dialog();
          this.login = true;
          const msgCon = {
                name: 'onConnect',
                args: [],
            };
          this.sendMsg(msgCon, msg.backend);
        }
      }
    } else {
      // here we assume that backend name is added automatically at backend-side
      const backend = msg.backend;
      if (!this.dataStream.item(backend)) {
        this.dataStream.add(backend, new Subject<Message>());
      }
      if (msg.name != "pong") {
        // console.log("received", msg);
      }
      if (msg.name === 'close-sanely') {
        this.disconnect();
      }
        
      this.dataStream.item(backend).next(msg);
    }
  }
} // end class MessageService
