import {
    Directive,
    Output,
    Input,
    EventEmitter,
    HostBinding,
    HostListener
  } from '@angular/core';
import { FileUploadService } from '../services/file-upload.service';
import { UiInfoService } from '../services/ui-info-service';
  
  @Directive({
    selector: '[appDnd]'
  })
  export class DndDirective {
    @HostBinding('class.fileover') fileOver: boolean; // why ??
    @Output() fileDropped = new EventEmitter<any>();
    @Output() fileUploaded = new EventEmitter<any>();
    @Input('appDnd') backend: string;
    @Input() disabled: boolean = false;

    constructor(private fileUploadService: FileUploadService,
                private uiInfoService: UiInfoService
                ) {}
  
    // MOUSE listener
    @HostListener('mouseenter', ['$event']) onMouseEnter(evt) {
      if (!this.disabled) {
        evt.preventDefault();
        evt.stopPropagation();
        // console.log("---mouseenter---", this.backend);
      }
    }

    @HostListener('mouseleave', ['$event']) onMouseLeave(evt) {
      if (!this.disabled) {
        evt.preventDefault();
        evt.stopPropagation();
        // console.log("---mouseleave---");
      }
    }

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
      if (!this.disabled) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
        // console.log("---drag over---");
      }
    }
  
    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
      if (!this.disabled) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        // console.log("---drag leave---");
      }
    }
  
    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
      if (!this.disabled) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        let files = evt.dataTransfer.files;
        // console.log("---drop---", files);
        this.fileDropped.emit(files);
        this.fileUploadService.saveFileAll(files[0], this.backend, "api-main").subscribe(data => {
            let message = data.message;
            // 240923: decided not to show this message here
            // this.uiInfoService.openSnackbar(message, 3000);
            //console.log("snackbar is missing!", message)
            this.fileUploaded.emit(files);
          },
          error => {
            console.log(error);
          })

      }
    }
  }
