import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { ProductService } from 'src/app/ui-main/services/product-service';

@Component({
  selector: 'app-modal-contact-us',
  templateUrl: './modal-contact-us.component.html',
  styleUrls: ['./modal-contact-us.component.scss']
})
export class ModalContactUsComponent {

  public formData: any = {}
  public serverUrlOlea: string = 'http://localhost:9001/api'

  EN = {
    getInTouch : "Let's get in touch",
    inTouchMessage : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe dolorum adipisci recusandae praesentium dicta",
    businessHours : "Business hours: M-F",
    quantecBot : "You can also ask QuantecBot any questions! Our AI assistant will help you through the website.",
    contactUs : "Contact us",
    send : "Send",
    message : "Message",
    fillFields : "You must fill all the fields",
    validEmail : "You must enter a valid email",
    emailSent : "Email sent successfully"
  }

  ES = {
    getInTouch : "Pongámonos en contacto",
    inTouchMessage : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe dolorum adipisci recusandae praesentium dicta",
    businessHours : "Horario de atención: L-V",
    quantecBot : "¡También puedes hacer preguntas a QuantecBot! Nuestro asistente de IA te ayudará a través del sitio web.",
    contactUs : "Contáctenos",
    send : "Enviar",
    message : "Mensaje",
    fillFields : "Debes completar todos los campos",
    validEmail : "Debes ingresar un email válido",
    emailSent : "Email enviado con éxito"
  }

  DE = {
    getInTouch : "Lass uns in Kontakt treten",
    inTouchMessage : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe dolorum adipisci recusandae praesentium dicta",
    businessHours : "Geschäftszeiten: M-F",
    quantecBot : "Du kannst auch QuantecBot Fragen stellen! Unser KI-Assistent wird dir über die Website helfen.",
    contactUs : "Kontaktiere uns",
    send : "Senden",
    message : "Nachricht",
    fillFields : "Sie müssen alle Felder ausfüllen",
    validEmail : "Sie müssen eine gültige E-Mail eingeben",
    emailSent : "E-Mail erfolgreich gesendet"
  }

  constructor(
    private dialogRef: MatDialogRef<ModalContactUsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public textFragments : TextFragmentService,
    private uiInfoService : UiInfoService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  sendEmail(): void {
    let { context, email } = this.formData;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!context || !email) {
      this.uiInfoService.openSnackbar(this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.fillfields, 3000);
    } else if (!emailPattern.test(email)) {
      this.uiInfoService.openSnackbar(this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.validEmail, 3000);
    } else {
      console.log("SENDING EMAIL", this.formData);
      this.productService.sendContactEmail(email, context).subscribe( () => {
        this.uiInfoService.openSnackbar(this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.emailSent, 3000);
      });
    }
  }

  // confirm(): void {
  //   this.dialogRef.close(true);
  // }

}
