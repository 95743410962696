<!--div class="flex flex-col h-full"-->
  <div class="flex flex-1 lg:px-[8%] h-full">
    <!-- SideMenu -->
    <aside class="w-64 h-full">
      <app-side-menu></app-side-menu>
    </aside>

    <div class="flex flex-1 flex-col items-center w-full h-full p-4 no-bottom">
      <div class="w-full flex justify-center mb-4 mt-0 border-b-2 border-solid border-vistrace-hover-light">
        <button class="tab-btn mr-4" (click)="activeTab = '0'" [class.active]="activeTab === '0'">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.personalDetails}}</button>
        <button class="tab-btn mr-4" (click)="activeTab = '1'" [class.active]="activeTab === '1'">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.accountDetails}}</button>
        <button class="tab-btn mr-4" (click)="activeTab = '2'" [class.active]="activeTab === '2'">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.payment}}</button>
        <button class="tab-btn"      (click)="activeTab = '3'" [class.active]="activeTab === '3'">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.invoices}}</button>
      </div>
      <!-- First Tab content -->
      <section *ngIf="activeTab === '0'" class="w-full pl-40 pr-40"> 
        <app-generic-form *ngIf="userinfo_key"
            [xname]="'settings-personal-form'" 
            [entity]="userinfo_key"
            [backend]="'main'" 
            [col_number]="2" 
            [frozen]="true" 
            [justifyContent]="'space-around'"
            [focusFirst]="true"
            [require_finish]="true"
            (onChange)="genericData($event)"
            [boundingBox]="false"
            [rebuildTrigger]="updateTrigger"
            >
        </app-generic-form>
        <label class="flex items-center pl-8">
          <mat-checkbox [checked]="agreeInfo" (click)="changeAgreeInfo()"></mat-checkbox>
          <!--input type="checkbox" class="form-checkbox h-6 w-6 mr-2 bg-vistrace-primary" [(ngModel)]="agreeInfo"-->
          <span class="text-gray-600">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.agreement}}</span>
        </label>
        <br>
        <label class="pl-8">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.learn}}
          <a href="/static/legals/vistrace-privacy-policy.pdf" download="vistrace-privacy-policy.pdf" (click)="openAndDownload($event, '/static/legals/vistrace-privacy-policy.pdf')" class="text-blue-500 underline cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.privacy}}</a></label><br>
        <button 
          (click)="updatePersonalData()" 
          class="w-1/4 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold ml-8 py-2 rounded-xl"
          [ngClass]="[!personalDataChanged ? 'disabled-button' : '']"
          [disabled]="!personalDataChanged"
          >
          {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.updatePersonalData}}
        </button>
      </section>
      <!-- Second Tab content -->
      <section *ngIf="activeTab === '1'" class="w-full pl-40 pr-40">
        <!--section class="flex items-center h-1/4 bg-blue-200 rounded-xl mb-10 p-10 cursor-pointer">
        </section-->
        <section class="flex flex-col justify-center items-center w-full rounded-xl cursor-pointer mb-5 border-2 border-solid border-gray-400">
          <div class="w-full"><p class="font-bold text-2xl pl-4 pt-4 text-left">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subscriptionDetails}}</p></div>
          <div class="flex flex-row p-4 w-full leading-relaxed">
            <div class="w-1/2 text-left">
              <div>
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.plan}}</div>
                <div class="text-gray-500 text-xl">{{toUpperCaseString(serviceName)}} </div>
              </div>
              <div class="pt-3">
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subscription}}</div>
                <div class="text-gray-500 text-xl">{{toUpperCaseString(subscriptionStatus)}} </div>
              </div>
              <div class="pt-3">
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.paymentStatus}}</div>
                <div class="text-gray-500 text-xl">{{toUpperCaseString(paymentStatus)}} </div>
              </div>
            </div>
            <div class="w-1/2 text-left pl-4">
              <div>
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productsAvailable}}</div>
                <div class="text-gray-500 text-xl">{{productsAvailable}}</div>
              </div>
              <div class="pt-3">
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.expiring}}</div>
                <div class="text-gray-500 text-xl ">{{expiringDate}} </div>
              </div>
              <div class="pt-3">
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.creditCard}}</div>
                <div class="text-gray-500 text-xl ">{{creditCard}} </div>
              </div>
            </div>
          </div>

        </section>
        <section class="flex flex-row justify-between w-full">
          <button (click)="toSubscriptions()" *ngIf="subscriptionStatus !== 'inactive' && subscriptionStatus !== 'unsubscribed' && subscriptionStatus !== '-'" class="w-60 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold py-2 ml-4 rounded-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.upgradePlan}}</button>
          <button (click)="toSubscriptions()" *ngIf="subscriptionStatus === 'inactive' || subscriptionStatus === 'unsubscribed' || subscriptionStatus === '-'" class="w-60 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold py-2 ml-4 rounded-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.purchasePlan}}</button>
          <button (click)="openConfirmUnsubscribed()" class="w-60 text-white font-bold ml-8 py-2 mr-4 rounded-xl"
            [disabled]="subscriptionStatus === 'inactive' || subscriptionStatus === 'active (unsubscribed)' || subscriptionStatus === '-'"
            [ngClass]="{
              'unsubscribed-bg': subscriptionStatus === 'inactive' || subscriptionStatus === 'active (unsubscribed)' || subscriptionStatus === '-',
              'subscribed-bg': subscriptionStatus !== 'inactive' && subscriptionStatus !== 'active (unsubscribed)' && subscriptionStatus !== '-'
            }">
            {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.unsubscribe}}
          </button>
        </section>
        
        <!--app-customer-payments-profile-component></app-customer-payments-profile-component-->
      </section>
      <!-- Third Tab content -->
      <section *ngIf="activeTab === '2'" class="w-full overflow-auto">
        
        <div class="flex flex-row">
          <div class="flex flex-col w-2/3 mt-2">

          <section *ngIf="this.purchaseIntentId && purchaseIntentDetails?.type_of_purchase=='plan' && purchaseIntentDetails?.workflow=='new'" class="flex flex-col w-full mb-3 px-10 pt-6 border-2 border-solid border-gray-400 rounded-xl">
              <div class="font-bold text-center text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.paymentDetails}}</div>
              <div class="flex flex-col p-4 w-full leading-relaxed">
                <div class="flex flex-row w-full mb-3">
                  <div class="w-2/5 font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.planPurchase}}</div>
                  <div class="w-1/5 text-gray-500 text-xl text-center">{{toUpperCaseString(purchaseIntentDetails["target_service_name"])}}</div>
                  <div class="w-2/5 text-gray-500 text-xl text-right">{{purchaseIntentDetails["plan_price_single_str"]}} €</div>
                </div>
                <div class="flex flex-row w-full">
                  <div class="w-3/5 font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.period}}: {{purchaseIntentDetails.new_period}}</div>
                  <div class="flex flex-row w-2/5 text-xl mt-10">
                    <div class="w-1/2 flex flex-col text-right">
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subTotal}}</div>
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.discount}} {{purchaseIntentDetails.purchase_reduction_perc_str}}%</div>
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.tax}} {{purchaseIntentDetails.purchase_tax_perc_str}}%</div>
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.grandTotal}}</div>
                    </div>
                    <div class="w-1/2 flex flex-col text-right">
                      <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_net_str"]}} €</div>
                      <div class="text-gray-500 text-xl">- {{purchaseIntentDetails["purchase_reduction_str"]}} €</div>
                      <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_tax_amount_str"]}} €</div>
                      <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_total_str"]}} €</div>
                    </div>
                  </div>
                </div>
              </div>
                <!-- <div class="w-1/2">
                  <div class="font-bold text-xl">Purchase intent</div>
                  <div class="font-bold text-xl">Plan Name</div>
                  <div class="font-bold text-xl">Plan Price</div>
                  <div class="font-bold text-xl">Discount</div>
                  <div *ngIf="purchaseIntentDetails['credit']==0" class="font-bold text-xl flex">
                    <div class="font-bold text-xl">Credit</div><mat-icon [matTooltip]="'due to unused time from previous plan'" >info</mat-icon>
                  </div>
                  <div class="font-bold text-xl">Tax Amount</div>
                  <div class="font-bold text-xl">Grand Total</div>
                </div>
                <div class="w-1/2pl-4">
                  <div class="text-gray-500 text-xl">Plan Subscription</div>
                  <div class="text-gray-500 text-xl">{{purchaseIntentDetails["target_service_name"]}}</div>
                  <div class="text-gray-500 text-xl">
                    <span class="price-tag">{{purchaseIntentDetails["plan_price_single_str"]}} €</span>
                  </div>
                  <div class="text-gray-500 text-xl">
                    <span class="price-tag">- {{purchaseIntentDetails["purchase_reduction_str"]}} €</span>
                  </div>
                  <div *ngIf="purchaseIntentDetails['credit']==0" class="text-gray-500 text-xl">
                    <span class="price-tag">{{purchaseIntentDetails["credit_str"]}} €</span>
                  </div>
                  <div class="text-gray-500 text-xl">
                    <span class="price-tag">{{purchaseIntentDetails["purchase_tax_amount_str"]}} €</span>
                  </div>
                  <div class="text-gray-500 text-xl">
                    <span class="price-tag">{{purchaseIntentDetails["purchase_total_str"]}} €</span>
                  </div>
              </div> -->
          </section>

          <section *ngIf="this.purchaseIntentId && purchaseIntentDetails?.type_of_purchase=='plan'  && purchaseIntentDetails?.workflow=='upgrade'" class="flex flex-col w-full mb-3 px-10 pt-6 border-2 border-solid border-gray-400 rounded-xl">
              <div class="font-bold text-center text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.paymentDetails}}</div>
              <div class="flex flex-col p-4 w-full leading-relaxed">
                <div class="flex flex-row w-full">
                  <div class="w-2/5 font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.planUpgrade}}</div>
                  <div class="w-1/5 text-gray-500 text-xl text-center">{{toUpperCaseString(purchaseIntentDetails["target_service_name"])}}</div>
                  <div class="w-2/5 text-gray-500 text-xl text-right">{{purchaseIntentDetails["plan_price_single_str"]}} €</div>
                </div>
                <div class="flex flex-row w-full">
                  <div class="w-1/2 font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.previousPlan}}</div>
                  <div class="w-1/2 text-gray-500 text-xl text-right">-{{purchaseIntentDetails["credit_str"]}} €</div>
                </div>
                <div class="flex flex-row w-full">
                  <div class="w-3/5 font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.period}}: {{purchaseIntentDetails.new_period}}</div>
                  <div class="flex flex-row w-2/5 text-xl mt-10">
                    <div class="w-1/2 flex flex-col text-right">
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subTotal}}</div>
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.discount}} {{purchaseIntentDetails.purchase_reduction_perc_str}}%</div>
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.tax}} {{purchaseIntentDetails.purchase_tax_perc_str}}%</div>
                      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.grandTotal}}</div>
                    </div>
                    <div class="w-1/2 flex flex-col text-right">
                      <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_net_str"]}} €</div>
                      <div class="text-gray-500 text-xl">- {{purchaseIntentDetails["purchase_reduction_str"]}} €</div>
                      <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_tax_amount_str"]}} €</div>
                      <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_total_str"]}} €</div>
                    </div>
                  </div>
                </div>
              </div>
          </section>

          <section *ngIf="this.purchaseIntentId && purchaseIntentDetails?.type_of_purchase=='product' && purchaseIntentDetails?.workflow=='extend'" class="flex flex-col w-full mb-3 px-10 pt-6 border-2 border-solid border-gray-400 rounded-xl">
            <div class="font-bold text-center text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.paymentDetails}}</div>
            <div class="flex flex-col p-4 w-full leading-relaxed">
              <div class="flex flex-row w-full mb-3">
                <div class="w-2/5 font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.additionalProducts}}</div>
                <div class="w-1/5 text-gray-500 text-xl text-center">{{ purchaseIntentDetails.number_of_extra_products }}</div>
                <div class="w-2/5 text-gray-500 text-xl text-right">{{ purchaseIntentDetails.product_price_single_str }} €</div>
              </div>
              <div class="flex flex-row w-full">
                <div class="w-3/5 font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.period}}: {{purchaseIntentDetails.new_period}}</div>
                <div class="flex flex-row w-2/5 text-xl mt-10">
                  <div class="w-1/2 flex flex-col text-right">
                    <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subTotal}}</div>
                    <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.discount}} {{purchaseIntentDetails.purchase_reduction_perc_str}}%</div>
                    <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.tax}} {{purchaseIntentDetails.purchase_tax_perc_str}}%</div>
                    <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.grandTotal}}</div>
                  </div>
                  <div class="w-1/2 flex flex-col text-right">
                    <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_net_str"]}} €</div>
                    <div class="text-gray-500 text-xl">- {{purchaseIntentDetails["purchase_reduction_str"]}} €</div>
                    <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_tax_amount_str"]}} €</div>
                    <div class="text-gray-500 text-xl">{{purchaseIntentDetails["purchase_total_str"]}} €</div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="relative flex flex-col justify-center items-center h-8 rounded-xl mb-3 p-10 border-2 border-solid border-gray-400">
            <div class="flex p-4 w-full leading-relaxed justify-between">
              <div class="w-1/2">
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.providedCard}}</div>
              </div>
              <div>
                <div class="text-gray-500 text-xl">{{creditCard}} </div>
              </div>
            </div>
          </section>

          <section class="relative flex flex-col justify-center items-center h-8 rounded-xl mb-3 p-10 border-2 border-solid border-gray-400">
            <div class="flex items-center p-4 w-full leading-relaxed justify-between">
              <div class="w-1/3">
                <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.voucher}}</div>
              </div>
              <div>
                  <input
                  [(ngModel)]="voucherNumber" class="mt-2 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2" [disabled]="purchaseIntentDetails?.type_of_purchase=='product'"/>
                  <button (click)="updatePurchaseReduction()" [disabled]="voucherNumber==''" [ngClass]="[voucherNumber=='' ? 'disabled-button' : '']" class="w-20 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold ml-8 py-2 rounded-xl">
                    {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.claim}}
                  </button>
              </div>
            </div>
          </section>
          <section class="relative flex flex-col justify-center items-center rounded-xl mb-3 p-10 pt-6 border-2 border-solid border-gray-400">
            <div class="font-bold text-center text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.billingDetails}}</div>
            <app-generic-form *ngIf="userinfo_key"
                [xname]="'settings-billing-form'" 
                [entity]="company_key"
                [backend]="'main'" 
                [col_number]="2" 
                [frozen]="true" 
                [justifyContent]="'space-around'"
                [focusFirst]="true"
                [require_finish]="true"
                (onChange)="genericBillingData($event)"
                [boundingBox]="false"
                [errorCheck]="errorCheck"
                [rebuildTrigger]="updateTrigger"
                >
            </app-generic-form>
            <button (click)="updateBillingData()" 
              class="w-1/4 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold ml-8 py-2 rounded-xl"
              [disabled]="!billingDataChanged"
              [ngClass]="[!billingDataChanged ? 'disabled-button' : '']">
              {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.updateBilling}}
            </button>
          </section>

          </div>
          <div class="w-1/3 flex justify-center">
            <app-subscription-card *ngIf="illustration=='plan'" class="mt-2 mb-5" [possible]="false" [subscriptionDesc]="sub"></app-subscription-card>
            <div *ngIf="illustration=='product'" class="flex flex-col">
              <img src="assets/images/productAdd.svg">
              <div class="flex items-center border-2 border-solid border-gray-400">
                <button (click)="counterChange('decrease')" [disabled]="purchaseIntentDetails.number_of_extra_products==1" class="px-3 py-1">
                  -
                </button>
                <span class="text-xl">{{ purchaseIntentDetails.number_of_extra_products }}</span>
                <button (click)="counterChange('increase')" class="px-3 py-1">
                  +
                </button>
              </div>
            </div>
          </div>
        </div>

        
        <div class="flex mb-5">
          
          <button (click)="startPaymentProcess()" 
            [matTooltipDisabled]="billingReady" 
            [matTooltip]="'Please provide billing data'"
            [disabled]="!billingReady || !this.purchaseIntentId" 
            class="w-1/4 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold ml-8 py-2 rounded-xl"
            [ngClass]="[(!billingReady || !this.purchaseIntentId) ? 'disabled-button' : '']"
            >
            {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.payNow}}
          </button>
          <button (click)="cancelPaymentProcess()" 
            class="w-1/4 bg-vistrace-light-grey hover:bg-vistrace-grey text-white font-bold ml-8 py-2 rounded-xl"
            [disabled]="!this.purchaseIntentId" 
            [ngClass]="[(!this.purchaseIntentId) ? 'disabled-button' : '']"
            >
            {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cancelPayment}}
          </button>
        </div>
        
      </section>
      <!-- Fourth Tab content -->
      <div *ngIf="activeTab === '3'" class="h-[calc(100vh-250px)] overflow-auto">
        <app-database-table [xname]="invoice_table"
          [onFilter]="true"
          [backend]="'main'"
          [enabled]="false"
          [row_key]="'invoice_number'"
          [page_size]="6"
          [onEditor]="false">
        </app-database-table>
      </div>
    </div>
  </div>