import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ResetPwDialogComponent } from 'src/app/shared-generic/components/reset-pw-dialog/reset-pw-dialog.component';
import { DialogLoginService } from 'src/app/shared-generic/services/dialog-login.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { UpdateService } from 'src/app/shared-generic/services/update.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  newPassword: string = '';
  confirmPassword: string = '';

  passwordRecover = {
    new_password: this.newPassword,
    secret_number: this.messageService.secret_number
  };

  public subs: Subscription[] = [];
  constructor(
    public msgService: MessageService,
    public router : Router,
    public messageService: MessageService,
    public loginService: DialogLoginService,
    public _Activatedroute: ActivatedRoute,
    public updateService: UpdateService,
    private uiInfoService : UiInfoService,
    private spinnerService: NgxSpinnerService,
    private http : HttpClient
    ) { }

  onSubmit(): void {
    // NOTE only necessary call to login is:
    // this.dialogLoginService.login(this.email, this.password);

    if(this.newPassword.length < 8) {
      this.uiInfoService.openSnackbar("Password must be at least 8 characters long", 3000);
    } else if (this.newPassword !== this.confirmPassword) {
      this.uiInfoService.openSnackbar("Passwords do not match", 3000);
    } else {
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // hash the password before sending to the backend
      const salt = this.messageService.salt;
      const hash = this.messageService.hexdigest_n(this.newPassword+salt, 100);
      this.passwordRecover["new_password"] = hash;
      this.spinnerService.show();
      // add the data to the body of the request
      this.http.post('/reset_pw', this.passwordRecover)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        if (response.success) {
          this.router.navigate(['/login-view/login']);
          this.uiInfoService.openSnackbar(response.answer, 3000);
        } else {
          this.uiInfoService.openSnackbar(response.error, 3000);
        }
      }, error => {
        console.error('Error resetting password', error);
        this.uiInfoService.openSnackbar(error.error, 3000);
      });
    }
    
  }

  ngOnInit(): void {

  }


  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  public startNewLogin():void {
  }

  toRegister() {
    this.router.navigateByUrl("/register")
  }

}
