<div class="flex items-center bg-zinc-100 rounded-3xl p-4 h-full relative overflow-hidden">
  <img
    (click)="toProductInfo()"
    [src]="product.url_img"
    alt="Product Image"
    class="w-2/12 h-full rounded-2xl"
  />
  <div class="w-8/12 h-full flex flex-col items-start justify-around ml-4">
    <p class="text-lg font-bold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.brand}}: {{ product.brand_name }}</p>
    <p class="text-lg font-bold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.product}}: {{ product.name }}</p>
    <p class="text-lg font-bold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.batches}}: {{ product.number_of_batches }}</p>
  </div>
  <div class="absolute w-[300px] right-[30px] bottom-5 h-8 flex flex-row items-center justify-center">

      <qrcode
        #parent
        style="display: none"
        (qrCodeURL)="onCodeChange($event)"
        imageSrc="../assets/vistracelogo_grey_square_small.png"
        [qrdata]="qrCodeDownloadLink"
        [width]="500"
        [imageHeight]="75"
        [imageWidth]="75"
        [errorCorrectionLevel]="'M'"
        margin="10"
      ></qrcode>


    <button matTooltip="Download QR Code" mat-mini-fab class="w-8 h-8 m-2 cursor-pointer" (click)="saveAsImage(parent)">
        <mat-icon class="text-white">qr_code</mat-icon>
    </button>
    <button matTooltip="Mobile Preview" mat-mini-fab class="w-8 h-8 m-2 cursor-pointer" (click)="previewClicked()">
      <mat-icon class="text-white">remove_red_eye</mat-icon>
    </button>
    <button matTooltip="Delete Product" mat-mini-fab class="w-8 h-8 m-2 cursor-pointer" (click)="deleteProduct()">
        <mat-icon class="text-white">delete</mat-icon>
    </button>
    <button matTooltip="Copy Product" mat-mini-fab class="w-8 h-8 m-2 cursor-pointer" (click)="copyProduct()">
      <mat-icon class="text-white">content_copy</mat-icon>
    </button>
    <button matTooltip="Edit Product" mat-mini-fab class="w-8 h-8 m-2 cursor-pointer" (click)="toProductInfo()">
      <mat-icon class="text-white">edit</mat-icon>
    </button>
  </div>

  <div class="badge-www" *ngIf="product.isFinalized">
    <span>online</span>
  </div>
  <div class="badge-review" *ngIf="product.status==='in-review'">
    <span>review</span>
  </div>
</div>
