import { Component, OnInit } from '@angular/core';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';
import { ModalContactUsComponent } from '../modal-contact-us/modal-contact-us.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalUnsubscribeConfirmationComponent } from '../modal-unsubscribe-confirmation/modal-unsubscribe-confirmation.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  EN = {
    terms : "Terms",
    privacy : "Privacy",
    contact : "Contact",
    about : "About Us",
  }

  ES = {
    terms : "Términos",
    privacy : "Privacidad",
    contact : "Contacto",
    about : "Sobre Nosotros",
  }

  DE = {
    terms : "Bedingungen",
    privacy : "Datenschutz",
    contact : "Kontakt",
    about : "Über uns",
  }

  constructor(public textFragments : TextFragmentService,
              private dialogRef : MatDialog,
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }
  // Method to open the PDF in a new tab and trigger the download
  openAndDownload(event: MouseEvent, url: string): void {
    event.preventDefault(); // Prevent the default anchor tag behavior

    // Open the PDF in a new tab
    window.open(url, '_blank');

    // Create an element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'download.pdf'; // Dynamically getting the file name from URL
    document.body.appendChild(link);
    link.click(); // Programmatically click the link to start the download
    document.body.removeChild(link); // Remove the element after download
  }

  openContactUs(){
    this.dialogRef.open(ModalUnsubscribeConfirmationComponent)
    
  }
}
