import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-modal-confirm-product',
  templateUrl: './modal-confirm-product.component.html',
  styleUrls: ['./modal-confirm-product.component.scss']
})
export class ModalConfirmProductComponent implements OnInit {

  EN = {
    confirm : "Confirm",
    cancel : "Cancel",
  }

  ES = {
    confirm : "Confirmar",
    cancel : "Cancelar",
  }

  DE = {
    confirm : "Bestätigen",
    cancel : "Abbrechen",
  }

  constructor(
    private dialogRef: MatDialogRef<ModalConfirmProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public textFragments : TextFragmentService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

}
