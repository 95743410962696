import { Component, ViewEncapsulation, OnInit, OnChanges, Input, OnDestroy, SimpleChanges, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { filter, startWith, map } from 'rxjs/operators';
import { FormField } from './../../types/form-field';
import { FormDataService } from './../../services/form-data.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MessageService } from './../../services/message.service';
import { Dictionary } from './../../types/dictionary';
import { MatCalendarCellClassFunction} from '@angular/material/datepicker';
import { HolidayService } from './../../services/holiday.service';
import { DialogService } from './../../services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './../../components/dialog/dialog.component';
import { TextService } from '../../services/text.service';
import * as moment from 'moment';
import { FileUploadService } from '../../services/file-upload.service';
import { FileDownloadService } from '../../services/file-download.service';
import { PasswordService } from '../../services/password.service';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title = '';
  @Input() fontsize = 14;
  @Input() titlesize = 16;
  @Input() formPadding = 30;
  @Input() xname: string;
  @Input() col_number: number = 2;
  @Input() show: boolean = true;
  @Input() entity: string = '';
  @Input() frozen: boolean = false;   // if true form has no delete botton
  @Input() boundingBox: boolean = true;
  @Input() backgroundColor: boolean = false;
  @Input() infoForm: boolean = false; // no color change and all fields disabled
  @Input() partialInfoForm: boolean = false;
  @Input() updateTrigger = '';
  @Input() rebuildTrigger = '';
  @Input() emitValidStatus = false;   // emits form status (valid) on status changes
  @Input() emitNameValidStatus = false; // emits form name and status (valid) on status changes
  @Input() updateItemsCallbackId = ""; // callback used to update form items after one has been added/removed
  @Input() backend = 'offer-creator';
  @Input() updateCols = [];
  @Input() password = false;
  @Input() init = 100;
  @Input() deleteMessage = '';
  @Input() require_finish = false; // if true, the values are not written on change in db
  @Input() focusFirst = '';  // focus the first input
  @Input() spacer=0;
  @Input() crossedDates = [];
  @Output() onChange = new EventEmitter<any>();
  @Output() skipFormEvent = new EventEmitter<any>();
  @Output() updateKeyEvent = new EventEmitter<any>();
  @Input() justifyContent = 'space-between' // 'flex-start': with not many fields this option shoud be used
  @Input() formSpacing = true;
  // @Input() obj_key: string = "";
  @Input() errorCheck = 0;

  public formFieldsList = [];
  public formFieldsOrigList = [];
  public formFieldsOrigListX = [];
  public focus_number = 0;
  public subs: Subscription[] = [];
  public valueCallbackId = '';
  // public init = 100;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});
  public filteredOptions = new Dictionary<Observable<string[]>>();
  public selectPickerOptions = new Dictionary<any[]>();
  public listItems = new Dictionary<string[]>();
  public group = {};
  public blockLoop = false;
  public monthHolidays = [];
  public delete_dialog_name = '';
  public delete_file_dialog_name = '';
  public callBackToken = 'callBackToken';
  public current_file_name = '';
  public defaultHeight = 92;
  // options = [];
  // autoControl = new FormControl();
  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    // NOTE: for now all datepickers in a form will display line-through if crossedDates are defined
    if (view === 'month') {
      let date_month = cellDate.month()+1
      const date = cellDate.year()+'-'+date_month+'-'+cellDate.date();
      if (this.monthHolidays.includes(date) && this.crossedDates.includes(date) ){
        return 'example-custom-date-class crossed-custom-date-class';
      }else if (this.monthHolidays.includes(date)){
        return 'example-custom-date-class';
      }else if (this.crossedDates.includes(date)){
        return 'crossed-custom-date-class';
      }
    }
    return '';
  }
  constructor(
    private formDataService: FormDataService,
    private messageService: MessageService,
    private holidayService: HolidayService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private textService: TextService,
    private fileUploadService: FileUploadService,
    private fileDownloadService: FileDownloadService,
    private passwordService: PasswordService) { }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  ngOnInit() {
    this.initialize();
    }

  @HostListener('keydown', ['$event'])
    onKeyDown(e) {
        // console.log("key code: ", e.code);
        // optionally use preventDefault() if your combination
        // triggers other events (moving focus in case of Shift+Tab)
        let skipForm = false;
        if (!e.shiftKey && e.keyCode == 9) {
            e.preventDefault();
            this.focus_number += 1;
            if (this.focus_number >= this.formFieldsOrigList.length) {
              this.focus_number = 0 //this.formFieldsOrigList.length - 1;
              skipForm = true;
            }
        } else if (e.shiftKey && e.keyCode==9) {
            e.preventDefault();
            this.focus_number -=1
            if (this.focus_number < 0) {
              this.focus_number = this.formFieldsOrigList.length - 1; // 0;
            }
        } 
        if (e.keyCode==9) {
          // console.log(this.formFieldsOrigListX)
          const id_name = this.formFieldsOrigListX[this.focus_number];
          const targetElem = document.getElementById(id_name);
          if (targetElem) {
            targetElem.focus();
          }
          // console.log("FOCUS---", col_name, this.focus_number);
          if (skipForm) {
            this.skipFormEvent.emit();
          }
        }
  }

  onFocus($event) {
    // FOCUS click event

    var field = this.formFieldsOrigListX.indexOf($event);
    const targetElem = document.getElementById($event);
    //targetElem.focus();  // why??!!
    this.focus_number = field;
    //console.log("FOCUS...", $event, this.focus_number);
  }

  focusFirstInput() {
    // focus from external
    this.focus_number = 0;
    const col_name = this.formFieldsOrigListX[this.focus_number];
    const targetElem = document.getElementById(col_name);
    // console.log("....TARGET", targetElem);
    if (targetElem) {
      targetElem.focus();
    }
  }

  deleteInput(col_name: any) {
    const control = this.formGroup.get(col_name);
    control.setValue("")
  }

  addToList(col_name: any, type_of_field: string) {
    var value = this.formGroup.get(col_name).value;
    var list_x: any[] = [];
    list_x = this.listItems.item(col_name);
    if (type_of_field == "listInputInt"){
      value = Math.floor(+value);
    }
    // NOTE check if value is already in list and do not add if so
    // check on empty input and do not add if so
    if (!list_x.includes(value) && value!=="") {
      list_x.push(value);
    }
    if (typeof value == "number"){
      list_x.sort(function(a, b){return a - b});
    }
    this.formDataService.setGenericFormFieldValue(this.xname, this.entity, col_name, list_x, this.backend);
    this.formGroup.get(col_name).setValue("");
    const dict_key_value = {} as any;
    dict_key_value[col_name] = list_x;
    this.onChange.emit(dict_key_value);
  }

  removeFromList(value: string, col_name: string) {
    // ***
    console.log("Remove from LIST");
    this.listItems.setItem(col_name, this.listItems.item(col_name).filter(x=> x!==value));
    var list_x = this.listItems.item(col_name);
    this.formDataService.setGenericFormFieldValue(this.xname, this.entity, col_name, list_x, this.backend);
    const dict_key_value = {} as any;
    dict_key_value[col_name] = list_x;
    this.onChange.emit(dict_key_value);
  }
  
  initialize() {
    this.callBackToken = this.callBackToken + this.entity;
    const callbackIdForm = 'form/' + this.xname + "/" + this.entity;
    setTimeout( ()=> this.init = 0, 500); // set to 500 ms (---> to be validated)
    // DEBUG this.init is usefull but must be adjusted otherwise !!!!
    this.subs.push(this.formDataService.getGenericForm(this.xname, callbackIdForm, this.backend).pipe(filter(msg => msg.name === callbackIdForm))
      .subscribe( msg => {
        this.formFieldsList = [];
        this.formFieldsOrigList = [];
        this.formFieldsOrigListX = [];
        //let formFieldsList = [];
        //let group = {};
        //this.init = msg.args.form.length;
        msg.args.form.forEach(field => {
          let formField = FormField.parseFormField(field);
          // console.log(".........",formField);
          // formFieldsList.push(formField);
          this.formFieldsOrigList.push(formField);
          this.formFieldsOrigListX.push(this.entity+formField.column_name);
          // create form controls
          let disabled = this.infoForm ? this.infoForm : formField.disabled;
          if (formField.validate_pattern && formField.required && formField.type_of_field!=="listInput") {
            this.group[formField.column_name] = new UntypedFormControl({value: '', disabled: disabled}, Validators.compose([Validators.required, Validators.pattern(formField.validate_pattern)]));
          } else if (formField.required && formField.type_of_field!=="listInput") {
            //console.log("REQUIRED", formField.column_name);
            this.group[formField.column_name] = new UntypedFormControl({value: '', disabled: disabled}, Validators.required);
          } else if (formField.validate_pattern) {
            this.group[formField.column_name] = new UntypedFormControl({value: '', disabled: disabled}, Validators.pattern(formField.validate_pattern));
          } else {
            this.group[formField.column_name] = new UntypedFormControl({value: '', disabled: disabled});

          }
          this.group["matSelectSearch"+formField.column_name] = new UntypedFormControl({value: '', disabled: false});
          if (formField.type_of_field === 'autoComplete') {
              let options = formField.select_options;
              this.filteredOptions.add(formField.column_name, this.group[formField.column_name].valueChanges.pipe(
                startWith(''),
                map((x: string) => this._filter(x, options))
              ));
              this.subs.push(this.group[formField.column_name].valueChanges.subscribe( x => {
                // console.log("this init setAutoComplete ....", this.init, formField.column_name)
                // prevent the init call, because it is not usefull anyway
                if (this.init == 0) {
                    // only emit change event by hand changes
                    const dict_key_value = {} as any;
                    dict_key_value[formField.column_name] = x;
                    this.onChange.emit(dict_key_value);
                    if (!this.require_finish) {
                      this.formDataService.setAutoCompleteFieldValue(this.xname, this.entity, formField.column_name, x, this.backend, this.valueCallbackId);
                    }
                } else {
                    const t_out = Math.min(500, this.init);
                    setTimeout( ()=> this.init = 0, t_out);
                }
              }));
          } else if (formField.type_of_field === 'listInput' || formField.type_of_field === 'listInputInt') {
            // ***
            // NOTE: do not directly connect to backend database here
            // the events are handled in addToList, removefromList
            let options = formField.select_options;
              this.filteredOptions.add(formField.column_name, this.group[formField.column_name].valueChanges.pipe(
                startWith(''),
                map((x: string) => this._filter(x, options))
              ));
          } else {  // not autoComplete and not listInput and not listInputInt
            if (formField.type_of_field === 'selectPicker') {
              this.selectPickerOptions.add(formField.column_name, formField.select_options);
            } else if (formField.type_of_field === 'selectPickerSearch') {
              let options = formField.select_options;
              this.filteredOptions.add(formField.column_name, this.group['matSelectSearch'+formField.column_name].valueChanges.pipe(
                startWith(''),
                map((x: string) => this._filter(x, options))
              ));
              //this.selectPickerOptions.add(formField.column_name, formField.select_options);
            } 
            if (formField.type_of_field === 'datePicker'){
              // on value changes
              this.subs.push(this.group[formField.column_name].valueChanges.subscribe( x => {
                // console.log("this init ....", this.init, typeof x)
                if (this.init == 0) {
                  let str_x = "";
                  if (x) {
                    let month = x.month() + 1;
                    str_x =[ x.year(), month, x.date()].join("-");
                    // console.log("does it enter here?", formField.column_name, x)
                  }
                  // console.log("should ",  );
                  // Correct expression: 1 is sufficient not to be send to backend
                  // either entity="create-new" or if entity is a key
                  // require_finish=true
                  if (!this.require_finish) {
                    this.formDataService.setGenericFormFieldValue(this.xname, this.entity, formField.column_name, str_x, this.backend);
                  }
                  const dict_key_value = {} as any;
                  dict_key_value[formField.column_name] = str_x;
                  this.onChange.emit(dict_key_value);
                } else {
                  const t_out = Math.min(500, this.init);
                  setTimeout( ()=> this.init = 0, t_out);
                }
              }));

            } else { // notDatepicker
              // on value changes
              this.subs.push(this.group[formField.column_name].valueChanges.subscribe( x => {
                // console.log("this init ....", this.init, typeof x)
                if(formField.type_of_field === 'colorInput'){
                  if (!x){
                    x=""
                  }else if (typeof(x) != "string"){
                    x = "#" + x["hex"]
                  }
                }
                if (this.init == 0) {
                  // console.log("does it enter here?", formField.column_name, x)
                  if (!this.require_finish) {
                    if (typeof(x)!="string" || x.length <= this.textService.limit_of_input) {
                      this.formDataService.setGenericFormFieldValue(this.xname, this.entity, formField.column_name, x, this.backend);
                    }
                  }
                  const dict_key_value = {} as any;
                  dict_key_value[formField.column_name] = x;
                  this.onChange.emit(dict_key_value);
                } else {
                  // console.log("does it enter wrong?", this.init)
                  const t_out = Math.min(500, this.init);
                  setTimeout( ()=> this.init = 0, t_out);
                }
              }));
            }
          }
          if (this.entity == "create-new" && (formField.type_of_field == "listInput" || formField.type_of_field == "listInputInt")) {
            this.listItems.add(formField.column_name, []);
          }
        });
        this.formGroup = new UntypedFormGroup(this.group);
        if (this.emitValidStatus) {
          this.formGroup.statusChanges.subscribe(val => {
            // action on status change: must be reviewed !!!
            this.formDataService.setFormStatus(this.formGroup.valid);
          });
        }
        if (this.emitNameValidStatus) {
          this.formGroup.statusChanges.subscribe(val => {
            // action on status change: must be reviewed !!!
            // console.log("status change ...", val, this.formGroup.valid);
            // QUES: why is this.formGroup.valid = false, if infoForm = True?; val == DISABLED
            if (this.infoForm) {
              this.formDataService.setSingleStatus(this.xname, true);
            } else {
              this.formDataService.setSingleStatus(this.xname, this.formGroup.valid);
            }
          });
        }
        if (!this.infoForm && this.emitNameValidStatus) {
          for (let formField of this.formFieldsOrigList) {
            if (!formField.disabled) {
              this.formGroup.get(formField.column_name)?.statusChanges.subscribe(status => {
                if (status == "VALID") {
                  this.formDataService.setSingleFieldStatus(this.xname+formField.column_name, true);
                } else {
                  this.formDataService.setSingleFieldStatus(this.xname+formField.column_name, false);
                }
              });
            }
          }
        }
        // split fields into columns
        
        for(var i = 0; i < this.formFieldsOrigList.length; i += this.col_number) {
          for (var n = 0; n < this.col_number; n+= 1) {
            if (this.formFieldsList[n] && this.formFieldsOrigList[i + n]) {
              this.formFieldsList[n].push(this.formFieldsOrigList[i + n]);
            } else {
              if (this.formFieldsOrigList[i + n]) {
                this.formFieldsList[n] = [this.formFieldsOrigList[i+n]];
              }
            }
          }
        }
        // FOR TAB focus change: what to do?

        /*
        // CHANGE to rows keep the col_number ... for the focus change
        for (var n = 0; n < formFieldsList.length; n+= 1) {
            var row = Math.floor(n/this.col_number)
            if (!this.formFieldsList[row]) {
              this.formFieldsList[row] = [formFieldsList[n]];
            } else {
              this.formFieldsList[row].push(formFieldsList[n]);
            }
          }
          */
        // 211008: the following was moved here: tbd in code review
        const msg3 = {
          name: 'getFormValues',
          args: [this.entity, this.xname, [], this.valueCallbackId],
        };
        // next line prevents only the data fetch from db
        if (this.entity!='create-new') {
          this.messageService.sendMsg(msg3, this.backend);
        }
      }));

      this.subs.push(this.formDataService.checkViewStatusSubject.pipe(filter(msg => msg === this.xname))
      .subscribe(msg =>
        this.formDataService.setFormStatus(this.formGroup.valid)
        ));
      // as a random number too few digits (overlap probability is too high)
      this.valueCallbackId = this.messageService.getRandomInt(1000000).toString();
      this.updateKeyEvent.emit(this.valueCallbackId)
      // const callbackId = this.xname + this.entity;
      this.subs.push(
          this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === this.valueCallbackId))
          .subscribe(msg => {
              this.formFieldsOrigListX = [];
              for (const field in msg.args.data) {//210311 code review: was: this.formGroup.controls, decided to loop over message
                  const value = msg.args.data[field];
                  const control = this.formGroup.get(field);
                  const formField = this.formFieldsOrigList.filter(x=>x.column_name==field)[0]
                  // next line makes correct auto-complete lists
                  // NOTE: this does not work for separated forms (effects_on and select_options_request_handle in different formItem rows)
                  if (!formField) {
                    continue;
                  }
                  if (formField.effects_on.length>0) {
                      this.formDataService.setAutoCompleteFieldValue(this.xname, this.entity, formField.column_name, value, this.backend, this.valueCallbackId);
                  }
                  if (!(formField.type_of_field=="listInput" || formField.type_of_field=="listInputInt")) {
                    // ***
                    //set Value is excluded for the listInput type....
                    if (control && control.value !== value) {//added the flag control to check if field is in formGroup @OLI: please check
                      //210903: set init = 100 here, as this callback is based on getFormValues 
                      // (Problem: also called by auto-updates like formulars etc.)
                      // for getFormValues we do not want to call setGenericFormFieldValue or setAutoCompleteValue
                      // maybe a short timeout is sufficient (TO BE CHECKED!!!)
                      this.init = 10;
                      // console.log("setValue: ", value, "was: ", control.value);
                      control.setValue(value);
                    }
                    if (formField.type_of_field === 'fileUploadPreview') {
                      if (value) {
                        formField.upload_finished = true;
                      }
                    }
                  } else {
                    // console.log("ADD to listItems", field, value);
                    this.listItems.add(field, value);

                  }
                  this.formFieldsOrigListX.push(this.entity+field)
              }
          }));
      // 211008: this was moved within awaited getFormData above
      // const msg = {
      //     name: 'getFormValues',
      //     args: [this.entity, this.xname, [], this.valueCallbackId]
      // };
      // this.messageService.sendMsg(msg, this.backend);
      //((msg.args.name === 'updateAutoSelectOptions') && (msg.args.form_name === this.xname))

      this.subs.push(this.messageService.awaitMessage(this.backend)
      .pipe(filter(msg => ((msg.name === 'updateAutoSelectOptions') && (msg.args.form_name === this.xname))))
      .subscribe(msg => {
          // console.log("update form field.....");
          let formField = FormField.parseFormField(msg.args.field);
          this.blockLoop = false; // to prevent another getFormValues here !!! // new discussion 01.03.21 TODO: to be solved finally
          if ((formField.type_of_field === 'autoComplete')||(formField.type_of_field === 'listInput')||(formField.type_of_field === 'listInputInt')) {
              let options = formField.select_options;
              const group_local = this.group[formField.column_name];
              this.filteredOptions.add(formField.column_name, group_local.valueChanges.pipe(
                  startWith(''),
                  map((x: string) => this._filter(x, options))
                ));
              /*
              // DEBUG was added the first time, must not be added all the next times
              this.subs.push(group_local.valueChanges.subscribe( x => {
                // console.log(x);
                this.formDataService.setAutoCompleteFieldValue(this.xname, this.entity, formField.column_name, x, this.backend, this.valueCallbackId);
                // get default values for other fields
                // using this input
              }));
              */
          } else if (formField.type_of_field === 'selectPicker') {
            // console.log("new selectoptions: ", formField.select_options, " for: ", formField.column_name)
            this.selectPickerOptions.add(formField.column_name, formField.select_options);            
          } else if (formField.type_of_field === 'selectPickerSearch') {
            let options = formField.select_options;
            this.filteredOptions.add(formField.column_name, this.group['matSelectSearch'+formField.column_name].valueChanges.pipe(
              startWith(''),
              map((x: string) => this._filter(x, options))
            ));
          }
          }
      )
      );

      // get data from backend even though generic form is not connected with db (same callback as it would be connected)
      this.subs.push(
        this.messageService.awaitMessage(this.backend).pipe(filter(msg => ((msg.name === 'updateDataValueDependencies') && (msg.args.form_name === this.xname) && (msg.args.entity? msg.args.entity === this.entity: true))) )
        .subscribe(msg => {
          // console.log("updateDataValueDependencies.....", this.xname, msg.args.data);
          if (msg.args?.data) {
            for (const field in msg.args.data) {
                const value = msg.args.data[field];
                const control = this.formGroup.get(field);
                const formField = this.formFieldsOrigList.filter(x=>x.column_name==field)[0]
                if (formField.type_of_field == "listInput" || formField.type_of_field == "listInputInt") {
                  this.listItems.add(field, value);
                } else {
                  if (control && control.value !== value) {
                    // setting init to 10 is here important, as we would call backend again after we update frontend
                    this.init = 10;
                    //console.log("setValue: ", value, "was: ", control.value);
                    control.setValue(value);
  
                    const dict_key_value = {} as any;
                    dict_key_value[field] = value;
                    this.onChange.emit(dict_key_value);
                  }
                }
            }
          } else {
            const msg2 = {
              name: 'getFormValues',
              args: [this.entity, this.xname, msg.args.updateCols, this.valueCallbackId]//210311 code review: send the updateCols from backend
            };
            this.messageService.sendMsg(msg2, this.backend);
          }
        }));
      this.subs.push(this.messageService.awaitMessage(this.backend)
      .pipe(filter(msg => ((msg.name === 'rebuildComponent') && (msg.args.form_name === this.xname))))
      .subscribe(msg => {
            // console.log("rebuildComponent", this.xname)
            this.subs.forEach( subs => subs.unsubscribe() );
            this.initialize();
          }
      ));

      this.monthHolidays = this.holidayService.monthHolidays;
      this.subs.push(this.holidayService.getMonthHolidays().pipe()
      .subscribe(
        msg => {
          this.monthHolidays = msg.args.holidays;
        }
    ));

    if (this.deleteMessage) {
      this.delete_dialog_name = "delete-item" + this.xname + this.entity;
      this.subs.push(
        this.dialogService.statusSubject.pipe(filter(msg => msg.dialog_name === this.delete_dialog_name))
        .subscribe( msg => {
          if (msg.status){
            const msg = {
                name: 'deleteFormItem',
                args: [this.xname, this.entity, this.updateItemsCallbackId]
            }
            this.messageService.sendMsg(msg, this.backend);
          }
        })
      );
    }
    this.delete_file_dialog_name = "delete-file-item" + this.xname + this.entity;
    
    this.subs.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(message => message.name === this.callBackToken))
        .subscribe(message => {
          // console.log("open file with token: ")  
          this.fileDownloadService.downloadFile("/documents/generic-form/" + this.entity + "/" + this.current_file_name + `?token=${message.args.token}`);
          this.current_file_name = "";
        }
    ));
    }

    ngOnChanges(changes: SimpleChanges) {
      // perform correct actions depending on @Input that changed
      for (let propName in changes) {
        // console.log("CHANGE", propName);
        if (propName === 'focusFirst' && !this.blockLoop) {
          // console.log("FOCUS first...");
          this.focusFirstInput();
        }
        if (propName === "updateTrigger" && this.valueCallbackId && !this.blockLoop) {
          const msg = {
            name: 'getFormValues',
            args: [this.entity, this.xname, this.updateCols, this.valueCallbackId]
          };
          this.messageService.sendMsg(msg, this.backend);
        } else if (propName === "entity" && this.valueCallbackId && !this.blockLoop) {
          const msg = {
            name: 'getFormValues',
            args: [this.entity, this.xname, this.updateCols, this.valueCallbackId]
          };
          this.messageService.sendMsg(msg, this.backend);
        } else if (propName === "infoForm" && this.valueCallbackId && !this.blockLoop) {
          if(this.infoForm){
            this.formGroup.disable({onlySelf: true, emitEvent: false});
          } else {
            for (let formField of this.formFieldsOrigList) {
              if (!formField.disabled) {
                let formControl = this.formGroup.get(formField.column_name);
                formControl.enable({onlySelf: true, emitEvent: false});
              }
            }
          }
        } else  if((propName === "rebuildTrigger")  && this.valueCallbackId && !this.blockLoop) {
          // console.log("INIT new");
          this.subs.forEach( subs => subs.unsubscribe() );
          this.initialize();
      }
        this.blockLoop = false;
      }
      // the update trigger must be reset to 0 to prevent unwanted loops?
      // this.updateTrigger = '';
    }

    private _filter(value: any, options: any[]): string[] {
      let filterValue = "";
      let originValueType = "string";
      if (options.length == 0) {
        // due to a runtime error
        return [];
      }
      if (typeof options[0] == "number") {
        originValueType = "number";
        value = value.toString();
        options = options.map(option => option.toString());
      }
      if (value) {
        filterValue = value.toLowerCase();
      }
      var opts = options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
      if (originValueType == "number") {
        opts = opts.map(option => Number(option));
      }
      return opts;
    }

    public onDeleteItem() {
      if (this.deleteMessage) {
        const dialogRef = this.dialog.open(DialogComponent, {
          disableClose: true,
          width: '500px',
          data: {
            title: "Löschen",
            message: this.deleteMessage,
            name: this.delete_dialog_name}
          });
    }else{
        // console.log("delete item");
        const msg = {
            name: 'deleteFormItem',
            args: [this.xname, this.entity, this.updateItemsCallbackId]
        }
        this.messageService.sendMsg(msg, this.backend);
    }
    }

    public setReplacedText(replacedText, controlName){
      const control = this.formGroup.get(controlName);
      // console.log("....name:", this.entity+controlName);
      let targetElem = document.getElementById(this.entity+controlName);
      if (targetElem instanceof HTMLTextAreaElement || targetElem instanceof HTMLInputElement ){
        // console.log(targetElem.selectionStart, targetElem.selectionEnd);
        // let s_start = targetElem.selectionStart;
        // let s_end = targetElem.selectionEnd;
        // let cursor;
        let beginning, end;
        if (targetElem.selectionStart == targetElem.selectionEnd){
          // paste in the text
          beginning = control.value.slice(0,targetElem.selectionStart)
          end = control.value.slice(targetElem.selectionStart)
          // cursor = s_start+1;
        }else {
          // paste in the text, remove selection
          beginning = control.value.slice(0,targetElem.selectionStart)
          end = control.value.slice(targetElem.selectionEnd)
          // if only one selected, cursor is set behind the selection
          // cursor = s_end-1;
        }
        control.setValue(beginning + replacedText + end);
        // change cursor location (goes to the end by default)
        // setTimeout( ()=> {if (targetElem instanceof HTMLTextAreaElement || targetElem instanceof HTMLInputElement ){
        //   targetElem.setSelectionRange(cursor, cursor);
        // }}, 100);
      }


    }

    //public optionSelected(controlName:string, event) {
    //  // send value only if an option has been selected
    //  // values received from BE are not seen by this function
    //  this.formDataService.setAutoCompleteFieldValue(this.xname, this.entity, controlName, event.option.value, this.valueCallbackId);
    //}

    public checkOption(option: any) {
      if (option.constructor == Object && 'value' in option && 'label' in option ){
        return true;
      }else{
        return false;
      }
    }

    public disableInput(event){
      event.preventDefault();
    }

    public openDisabledEditor(formField: FormField){
      const dialogRef = this.dialog.open(FormDialogComponent, {
        disableClose: true,
        width: '500px',
        data: {
          title: "Editieren",
          form_dialog_name: "edit-disabled-field",
          backend: this.backend,
          form: this.xname + "-edit-" + formField.column_name,
          create_new: true
        }
        });
      dialogRef.afterClosed().subscribe(data => {
        if(data){
          // save entered value
          const control = this.formGroup.get(formField.column_name);
          control.setValue(data.input_values[formField.column_name]);
        }
      });
    }

    public generatePassword(formField: FormField){
      let new_password = this.passwordService.generatePassword(8, true, true, true);
      // save entered value
      const control = this.formGroup.get(formField.column_name);
      control.setValue(new_password);
    }

    public onFileUploaded($event, column_name: string) {
        var uploaded_file_name = $event[0].name;
        console.log("add ", uploaded_file_name, column_name);
        const msg = {
          name: 'addFileUploadedFile',
          args: [this.entity, uploaded_file_name, column_name]
        };
        this.messageService.sendMsg(msg, this.backend);
    }

    public openFile(file: string) {
      this.current_file_name = file;
      const msg = {
        name: 'getToken',
        args: [this.callBackToken]
      };
      this.messageService.sendMsg(msg, this.backend);
    }

    public removeFile(file: string, column_name: string) {
      this.current_file_name = file;
      const urlList = file.split("/"); // ***
      console.log("....", file, column_name);
      const fileNameOnly = urlList[urlList.length-1];
      const dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        width: '500px',
        data: {
          title: "Löschen",
          message: "Möchten Sie die Datei " + fileNameOnly + " wirklich löschen?",
          name: this.delete_file_dialog_name,
          column_name: column_name}
      });
      dialogRef.afterClosed().subscribe(data => {
        if(data){
          const msg_send = {
              name: 'deleteFile',
              args: [fileNameOnly, this.xname, this.entity, data.column_name, this.valueCallbackId]
          }
          this.messageService.sendMsg(msg_send, this.backend);
          const formField = this.formFieldsOrigList.filter(x=>x.column_name==column_name)[0];
          // console.log("upload finished", formField);
          formField.upload_finished = false;
        }
        this.current_file_name = "";      
      });
    }
    
    public inputUploadChange(event: any, column_name: string) {
      let file: File;
      if (event instanceof FileList) {
        file = event[0];
      } else {
        file = event.target.files[0];
      }
      console.log("START UPLOAD with", event)
      this.subs.push(
        this.fileUploadService.saveFileAll(file, this.backend, "api-main")
        .pipe(filter(data => data.filename==file.name))
        .subscribe(data => {
          const msg = {
            name: 'sendFileName',
            args: [this.backend, file.name, this.xname, this.entity, column_name, this.valueCallbackId]
          }
          this.messageService.sendMsg(msg, this.backend);
          // finally make image url available
          const formField = this.formFieldsOrigList.filter(x=>x.column_name==column_name)[0];
          // console.log("upload finished", formField);
          formField.upload_finished = true;
        },
          error => {
            console.log(error);
          })
      );
    }

    public checkForArray(value: any) {
      if (Array.isArray(value)) {
        return true;
      } else {
        return false;
      }
    }

  }
