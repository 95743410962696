import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  name: string = '';
  surname: string = '';
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  agreeTerms: boolean = false;
  agreeInfo: boolean = false;
  notARobot: boolean = false;

  user = {
    name: this.name,
    surname: this.surname,
    email_address: this.email,
    new_password: this.password,
    agreeTerms: this.agreeTerms,
    agreeInfo: this.agreeInfo
  };

  constructor(
    public router : Router,
    private uiInfoService : UiInfoService,
    private http : HttpClient,
    private messageService: MessageService,
    private spinnerService: NgxSpinnerService
    ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.user = {
      name: this.name,
      surname: this.surname,
      email_address: this.email,
      new_password: this.password,
      agreeTerms: this.agreeTerms,
      agreeInfo: this.agreeInfo
    };
    //console.log('User:', this.user);
    const onlyLettersPattern = /^[a-zA-Z]*$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!onlyLettersPattern.test(this.name) || !onlyLettersPattern.test(this.surname)) {
      this.uiInfoService.openSnackbar("Name and surname can only contain letters", 3000);
    } else if (!emailPattern.test(this.email)) {
      this.uiInfoService.openSnackbar("You must enter a valid email", 3000);
    } else if (this.password.length < 8) {
      this.uiInfoService.openSnackbar("Password must be at least 8 characters long", 3000);
    } else if (this.password !== this.confirmPassword) {
      this.uiInfoService.openSnackbar("Passwords do not match", 3000);
    } else if (!this.agreeTerms) {
      this.uiInfoService.openSnackbar("Please agree to the terms of service", 3000);
    } //else if (!this.notARobot) {
      //(this.uiInfoService.openSnackbar("Please confirm that you are not a robot", 3000);
    else {
      // add the data to the body of the request
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // hash the password before sending to the backend
      const salt = this.messageService.salt;
      const hash = this.messageService.hexdigest_n(this.user["new_password"]+salt, 100);
      this.user["new_password"] = hash;
      this.spinnerService.show();
      this.http.post('/register', this.user)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        if (response.success) {
          this.uiInfoService.openSnackbar(response.answer, 3000);
        } else {
          this.uiInfoService.openSnackbar(response.error, 3000);
        }
      }, error => {
        console.error('Error', error);
        this.uiInfoService.openSnackbar(error.error, 3000);
      });
    }
  }

  toLogin() {
    this.router.navigateByUrl("/login-view/login")
  }

  openAndDownload(event: MouseEvent, url: string): void {
    event.preventDefault(); // Prevent the default anchor tag behavior

    // Open the PDF in a new tab
    window.open(url, '_blank');

    // Create an element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'download.pdf'; // Dynamically getting the file name from URL
    document.body.appendChild(link);
    link.click(); // Programmatically click the link to start the download
    document.body.removeChild(link); // Remove the element after download
  }
}
