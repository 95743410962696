import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalPreviewComponent } from '../../vistrace-components/modal-preview/modal-preview.component';
import { Router } from '@angular/router';
import { ModalConfirmProductComponent } from '../../vistrace-components/modal-confirm-product/modal-confirm-product.component';
import { DashboardTitleService } from '../../services/dashboard-title.service';
import { HttpClient } from '@angular/common/http';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';
import { ProductService } from 'src/app/ui-main/services/product-service';
import { SessionService } from 'src/app/ui-main/services/session-service';
import { ModalReviewProductComponent } from '../../vistrace-components/modal-review-product/modal-review-product.component';


@Component({
  selector: 'app-product-info-menu',
  templateUrl: './product-info-menu.component.html',
  styleUrls: ['./product-info-menu.component.scss']
})
export class ProductInfoMenuComponent implements OnInit, OnDestroy {
  isModalOpen: boolean = false;
  product_info_finished = false;
  cultivation_finished = false;
  production_finished = false;
  bottling_finished = false;
  sales_finished = false;
  transport_finished = false;
  private subscriptions: Array<Subscription>=[];
  public selectedID = "Product Info";
  constructor(private dialogRef : MatDialog,
              private router : Router,
              private uiInfoService: UiInfoService,
              public titleService : DashboardTitleService,
              private spinnerService: NgxSpinnerService,
              private messageService: MessageService,
              public textFragments : TextFragmentService,
              public sessionService: SessionService,
              public productService: ProductService) 
  { }

  EN = {
    productInfo: 'Product Info',
    cultivation: 'Cultivation',
    production: 'Production',
    bottling: 'Bottling',
    sales: 'Sales',
    transport: 'Transportation',
    finalize: 'Finalize',
    preview: 'Preview',
    reject: 'Reject Product',
    back: 'Back',
  }

  ES = {
    productInfo: 'Información del producto',
    cultivation: 'Cultivo',
    production: 'Producción',
    bottling: 'Embotellado',
    sales: 'Ventas',
    transport: 'Transporte',
    finalize: 'Finalizar',
    preview: 'Previsualizar',
    reject: 'Rechazar Producto',
    back: 'Atrás',
  }

  DE = {
    productInfo: 'Produktinformation',
    cultivation: 'Anbau',
    production: 'Produktion',
    bottling: 'Abfüllung',
    sales: 'Verkauf',
    transport: 'Transport',
    finalize: 'Fertigstellen',
    preview: 'Vorschau',
    reject: 'Produkt ablehnen',
    back: 'Zurück',
  }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.titleService.selectedIDSubject.next(this.selectedID);
    this.titleService.selectedID$.subscribe(id => {
      this.selectedID = id;
    });
    setTimeout(()=>this.checkSectionStates(), 250);
    const callbackId = "routeBack";
    this.subscriptions.push(
      this.messageService.awaitMessage().pipe(filter(message => message.name === callbackId))
          .subscribe(message => {
            this.spinnerService.hide();
            this.toProducts();
          }
      )); 
  }

  checkSectionStates(): void {
    this.product_info_finished = this.titleService.formStates["Product-Info"].every(form => form.state === true);
    this.cultivation_finished = this.titleService.formStates["Cultivation"].every(form => form.state === true);
    this.production_finished = this.titleService.formStates["Production"].every(form => form.state === true);
    this.bottling_finished = this.titleService.formStates["Bottling"].every(form => form.state === true);
    this.sales_finished = this.titleService.formStates["Sales"].every(form => form.state === true);
    this.transport_finished = this.titleService.formStates["Transportation"].every(form => form.state === true);
  }

  onItemSelected(id: string) {
    console.log("SELECT", id);
    this.titleService.selectedIDSubject.next(id);
    setTimeout(()=>this.checkSectionStates(), 250);
  }

  isSelected(id: string) {
    return (this.selectedID===id);
  }

  isSelectedNone() {
    const titles = ["Product Info", "Cultivation", "Production", "Bottling", "Transportation", "Sales"];
    if (titles.indexOf(this.selectedID)==-1) {
      return true;
    } else {
      return false;
    }
  }

  openDialogPreview(){
    this.dialogRef.open(ModalPreviewComponent, {
      minWidth: '40vw',
      data: {
        productId: this.titleService.product_key
      }
    });
  }

  openDialogConfirm(): void {
    const dialogRef = this.dialogRef.open(ModalConfirmProductComponent, {
      disableClose: true,
      data: {
        'title': 'BEWARE!',
        'body': 'This action is irreversible. The information is secured in a blockchain system, such that it cannot be changed afterwards. Please make sure that all information is valid.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const body = {
        product_key: this.titleService.product_key
      }
      if (result) {
        const msg = {
          name: 'finalizeProduct',
          args: [body, "routeBack"]
        }
        this.productService.getProductData(this.titleService.product_key).subscribe( () => {
          console.log('GET PRODUCT DATA TO STORE IN FILE (no batch data here)');
          this.messageService.sendMsg(msg);
          this.spinnerService.show();
        })
        
        /*
        prom.then(value => {
          console.log('resolved', value);
        });
        */
      } else {
        console.log("REJECTED");
      }
    });
  }

  toProducts() {
    this.router.navigateByUrl("/main/products")
  }

  rejectProduct() {
    const body = {
      product_key: this.titleService.product_key,
      text: "You have not complied to our product image standards, please change."
    }
    const msg = {
      name: 'rejectReviewProduct',
      args: [body, "routeBack"]
    }
    this.messageService.sendMsg(msg);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( sub=>sub.unsubscribe() )
  }

  openDenialReason(){
    const dialogRef = this.dialogRef.open(ModalReviewProductComponent, {
      data: { id: this.titleService.product_key } 
    });
  }

}
