import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-create-product-button',
  templateUrl: './create-product-button.component.html',
  styleUrls: ['./create-product-button.component.scss']
})
export class CreateProductButtonComponent implements OnInit {
  @Input() leftover_product_number: number = 0;
  @Output() onBookProduct = new EventEmitter();

  EN = {
    add : 'add',
    product : 'Create New Product',
    left : 'Products left',
  };

  ES = {
    add : 'añadir',
    product : 'Crear nuevo producto',
    left : 'Productos restantes',
  }

  DE = {
    add : 'hinzufügen',
    product : 'Neues Produkt erstellen',
    left : 'Produkte übrig',
  }
  
  constructor(private router: Router,
              public textFragments : TextFragmentService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  toInput() {
    if (this.leftover_product_number>0) {
      this.router.navigateByUrl("/main/product-info-view/0")
    }
  }
  
  bookProduct() {
    this.onBookProduct.emit("new");
  }

}
