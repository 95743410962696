import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-delete-product-dialog',
  templateUrl: './delete-product-dialog.component.html',
  styleUrls: ['./delete-product-dialog.component.scss']
})
export class DeleteProductDialogComponent implements OnInit {

  EN = {
    header: "Delete Product",
    confirmationMessage: "Are you sure you want to delete the product:",
    delete: "Delete",
  };

  ES = {
    header: "Eliminar Producto",
    confirmationMessage: "¿Está seguro de que desea eliminar el producto:",
    delete: "Eliminar",
  };

  DE = {
    header: "Produkt löschen",
    confirmationMessage: "Möchten Sie das Produkt wirklich löschen:",
    delete: "Löschen",
  };

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }


  constructor(public dialogRef: MatDialogRef<DeleteProductDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              public textFragments : TextFragmentService) {

  }

  onNoClick(): void {
    this.dialogRef.close({"delete": false});
  }

  onYesClick(): void {
    this.dialogRef.close({"delete": true});
  }



}
